import { useState } from "react"
import NextImage from "next/image"
import Image from "components/Image"
import CallToAction from "components/CallToAction"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import Link from "next/link"

export default function HomeHero({ blok }) {
  const [practiceAreas, setPracticeAreas] = useState([])
  const [results, setResults] = useState([])

  async function fetchPracticeAreas() {
    try {
      const response = await fetch("/practice-area-search.json")
      const practiceAreas = await response.json()
      setPracticeAreas(practiceAreas)
    } catch (error) {
      console.error("Error fetching practice areas")
    }
  }
  return (
    <section className="relative">
      <div className="px-5 pt-6 relative overflow-hidden bg-primary">
        <div className="z-[-1]" aria-hidden>
          <NextImage
            alt=""
            src="/assets/blue-weave.webp"
            quality={80}
            fill
            priority
            sizes="50vw"
            style={{
              objectFit: "cover",
            }}
            aria-hidden
          />
        </div>
        <div className="max-w-screen-xl mx-auto relative z-10 flex flex-col lg:flex-row gap-4 sm:gap-12">
          <div className="basis-1/3">
            <h1 className="text-white lg:pt-24 lg:w-max lg:max-w-lg xl:max-w-2xl">{blok.heading}</h1>
          </div>
          <div className="basis-2/3">
            <Image
              src={blok?.image?.filename}
              alt={blok?.image?.alt}
              width={903}
              height={568}
              placeholder="blur"
              blurDataURL={blok?.image?.blurDataURL}
              sizes="65vw, (min-width: 1024px) 95vw"
              className="lg:w-[903px] lg:relative lg:min-w-max lg:max-h-[568px] lg:-left-32 h-full"
            />
          </div>
        </div>
      </div>
      <div className="bg-primary lg:pt-28 pb-10 lg:pb-16 -mt-0.5 relative">
        <div className="z-20 relative max-w-5xl mx-auto lg:absolute -top-4 lg:-top-32 lg:left-1/2 lg:-translate-x-1/2 lg:bg-blue-darkest lg:bg-opacity-80 px-5 lg:px-8 lg:py-8">
          <div className="z-10 flex flex-col lg:flex-row gap-8 mx-auto pb-8 lg:w-max" data-pagefind-ignore="all">
            {blok.buttons.map((button) => (
              <CallToAction
                href={getStoryblokLink(button.link)}
                key={`${button.label}-home-hero-button`}
                className="z-10 whitespace-nowrap sm:max-w-full lg:max-w-max"
              >
                {button.label}
              </CallToAction>
            ))}
          </div>
          <div className="lg:max-w-md mx-auto">
            <form className="searchbar-dark w-full relative z-50">
              <input
                onChange={async (e) => {
                  const { value } = e.currentTarget
                  // Dynamically load fuse.js and practice area list
                  const Fuse = (await import("fuse.js")).default
                  if (!practiceAreas.length) {
                    await fetchPracticeAreas()
                  }

                  const fuse = new Fuse(practiceAreas, { keys: ["name"] })
                  setResults(fuse.search(value))
                }}
                type="text"
                id="search"
                name="search"
                placeholder="I need help with..."
                maxLength={35}
              />
              {results.length ? (
                <div className="absolute w-full bg-white overflow-auto max-h-[25vh] z-40">
                  {results.map(({ item: { name, slug } }) => (
                    <Link className="block py-1 px-2 hover:bg-highlight-tint" key={slug} href={`/${slug}`}>
                      {name}
                    </Link>
                  ))}
                </div>
              ) : null}
            </form>
          </div>
        </div>
        <div className="max-w-screen-xl pt-12 lg:pt-0 relative z-10 mx-auto grid lg:grid-cols-4 gap-8">
          {blok.statistics.map((stat) => (
            <div className="" key={`${stat.number}-${stat.description}`}>
              <h2 className="text-white text-l2 sm:text-xl2 text-center">{stat.number}</h2>
              <h3 className="text-white text-m2 text-center">{stat.description}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
